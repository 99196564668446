import React from 'react'
import './index.alter.css'

const ButtonMenuAlter = ({ title, onClick }) => {
    return (
        <div className="wrapper" onClick={onClick}>
            <button>
                {title}
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>
    )
}

export default ButtonMenuAlter;